<template>
    <div class="uk-width-1-1">
        <!-- Header -->
        <p v-if="$can('READ', 'worker-payslip')" class="uk-text-bold">Payslip Details</p>
        <div v-if="$can('WRITE', 'worker-payslip') || $can('READ', 'worker-payslip') || $can('EDIT', 'worker-payslip')" class="uk-flex uk-flex-middle uk-flex-between myrobin-gap-10px">
            <div v-if="$can('EDIT', 'worker-payslip')" class="uk-width-expand">
                <button
                    v-if="uploadTemplateLoading"
                    type="button"
                    class="uk-button uk-button-default uk-border-rounded uk-width-expand"
                    disabled
                >
                    <span><div uk-spinner="ratio: 0.5"></div></span>
                    <span class="uk-margin-small-left">Loading</span>
                </button>
                <button
                    v-else
                    type="button"
                    class="uk-button uk-button-primary uk-border-rounded uk-width-expand uk-position-relative"
                    @click="triggerUploadTemplate"
                >
                    <img
                        class="uk-position-center-left uk-margin-small-left"
                        :src="`${images}/outline-upload.svg`"
                        alt="Upload icon"
                    >
                    <span>Upload Template</span>
                    <input
                        ref="uploadTemplate"
                        type="file"
                        style="display: none"
                        accept=".xls, .xlsx"
                        @change="uploadTemplate"
                    />
                </button>
            </div>
            <div v-if="$can('READ', 'worker-payslip')" class="uk-width-expand">
                <button
                    v-if="downloadReportPayslipBatchExcelButtonLoading"
                    type="button"
                    class="uk-button uk-button-default uk-border-rounded uk-width-expand"
                    disabled
                >
                    <span><div uk-spinner="ratio: 0.5"></div></span>
                    <span class="uk-margin-small-left">Loading</span>
                </button>
                <button
                    v-else
                    type="button"
                    class="uk-button uk-button-default uk-border-rounded uk-width-expand uk-position-relative myrobin-border-blue myrobin-color-blue"
                    @click="downloadReportPayslipBatchExcel"
                >
                    <img
                        class="uk-position-center-left uk-margin-small-left"
                        :src="`${images}/download-outline-blue.svg`"
                        alt="Upload icon"
                    >
                    <span>Download Report</span>
                </button>
            </div>
            <div v-if="$can('READ', 'worker-payslip')" class="uk-width-expand">
                <form class="uk-width-expand" @submit.prevent="filterPayslipBatchDetail">
                    <div class="uk-inline uk-width-expand">
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search" />
                        <input
                            class="uk-input uk-width-expand"
                            type="search"
                            aria-label="Not clickable icon"
                            placeholder="Search worker by name"
                            v-model="formSearch.worker_name"
                        />
                    </div>
                </form>
            </div>
            <div v-if="$can('READ', 'worker-payslip')" class="uk-width-expand">
                <button
                    type="button"
                    class="uk-button uk-button-primary uk-width-expand uk-border-rounded uk-position-relative myrobin-background-yellow"
                    @click="showLogs"
                >
                    <img
                        class="uk-position-center-left uk-margin-small-left"
                        :src="`${images}/timelapse-logs.svg`"
                        alt="Logs icon"
                    >
                    <span>Error Log</span>
                </button>
            </div>
            <div v-if="$can('WRITE', 'worker-payslip')" class="uk-width-expand">
                <button
                    type="button"
                    class="uk-button uk-button-primary uk-width-expand uk-border-rounded uk-position-relative"
                    @click="addWorker"
                >
                    <img
                        class="uk-position-center-left uk-margin-small-left"
                        :src="`${images}/add-user-outline.svg`"
                        alt="Add user icon"
                    >
                    <span>Add Mitra</span>
                </button>
            </div>
        </div>
        <!-- End header -->

        <!-- Table worker list -->
        <div
            v-if="$can('READ', 'worker-payslip') || $can('EDIT', 'worker-payslip') || $can('DELETE', 'worker-payslip')"
            class="uk-width-1-1 uk-card uk-card-default uk-margin-top"
        >
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-middle uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-text-left uk-text-nowrap">Fullname</th>
                            <th class="uk-text-left uk-text-nowrap">Email</th>
                            <th class="uk-text-left uk-text-nowrap">NIK</th>
                            <th class="uk-text-left uk-text-nowrap">Office</th>
                            <th class="uk-text-left uk-text-nowrap">Role</th>
                            <th class="uk-text-center uk-text-nowrap uk-width-small"></th>
                            <th class="uk-text-center uk-text-nowrap uk-width-small">Action</th>
                        </tr>
                    </thead>
                    <template v-if="tableLoading">
                        <Loading :colspan="7" />
                    </template>
                    <template v-else>
                        <tbody v-if="payslips.totalDocs > 0">
                            <tr v-for="(payslip, index) in payslips.docs" :key="index">
                                <td class="uk-text-left uk-text-nowrap">{{ payslip && payslip.fullname || '-' }}</td>
                                <td class="uk-text-left uk-text-nowrap">{{ payslip && payslip.email || '-' }}</td>
                                <td class="uk-text-left uk-text-nowrap">{{ payslip && payslip.identity_number || '-' }}</td>
                                <td class="uk-text-left uk-text-nowrap">{{ payslip && payslip.office_name || '-' }}</td>
                                <td class="uk-text-left uk-text-nowrap">{{ payslip && payslip.role_name || '-' }}</td>
                                <td class="uk-text-center uk-text-nowrap uk-width-small">
                                    <button
                                        type="button"
                                        @click="previewWorkerPayslip({
                                            action: 'preview-worker-payslip',
                                            workerPayslip: payslip
                                        })"
                                    >
                                        <img
                                            :src="`${images}/visibility.svg`"
                                            alt="View icon"
                                        >
                                    </button>
                                </td>
                                <td class="uk-text-center uk-text-nowrap uk-width-small">
                                    <div class="uk-flex uk-flex-column uk-flex-left uk-flex-top myrobin-gap-10px">
                                        <div v-if="$can('EDIT', 'worker-payslip')" class="uk-width-expand">
                                            <button
                                                type="button"
                                                class="uk-button uk-width-expand uk-button-primary uk-border-rounded"
                                                @click="editWorkerPayslip({
                                                    action: 'edit-worker-payslip',
                                                    workerPayslip: payslip
                                                })"
                                            >Edit</button>
                                        </div>
                                        <div v-if="$can('EDIT', 'worker-payslip') && !payslip.distributed" class="uk-width-expand">
                                            <button
                                                type="button"
                                                class="uk-button uk-button-default uk-width-expand uk-border-rounded"
                                                style="border-color: #0275D8; color: #0275D8;"
                                                @click="distributeWorkerPayslip({
                                                    action: 'edit-worker-payslip',
                                                    workerPayslip: payslip
                                                })"
                                            >Send to Mitra</button>
                                        </div>
                                        <div
                                            v-else-if="$can('EDIT', 'worker-payslip') && payslip.distributed"
                                            class="uk-width-expand"
                                            uk-tooltip="title: Payslip has been sent for this Mitra; pos: bottom-left"
                                        >
                                            <button
                                                type="button"
                                                class="uk-button uk-button-default uk-width-expand uk-border-rounded uk-position-relative"
                                                style="color: #FFC737;border-color: #FFC737;"
                                                @click="distributeWorkerPayslip({
                                                    action: 'edit-worker-payslip',
                                                    workerPayslip: payslip
                                                })"
                                            >
                                                <img class="uk-position-center-left uk-margin-small-left" :src="`${images}/info-green.svg`">
                                                <span>Send to Mitra</span>
                                            </button>
                                        </div>
                                        <div v-if="$can('DELETE', 'worker-payslip')" class="uk-width-expand">
                                            <button
                                                type="button"
                                                class="uk-button uk-width-expand uk-button-danger uk-border-rounded"
                                                @click="deleteWorkerPayslip({
                                                    action: 'delete-worker-payslip',
                                                    workerPayslip: payslip
                                                })"
                                            >Delete</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <EmptyTable v-else :colspan="7" />
                    </template>
                </table>
            </div>
            <Pagination
                :total-data="payslips.totalDocs || 0"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>
        <!-- End table worker list -->
    </div>
</template>

<script>
import { PREFIX_IMAGE } from "@/utils/constant";

export default {
    name: 'DetailPayslipMitra',
    components: {
        Loading: () => import('@/components/globals/tables/Loading'),
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
    },
    props: {
        uploadTemplateLoading: {
            type: Boolean,
            required: true,
            default: false
        },
        tableLoading: {
            type: Boolean,
            required: true,
            default: false 
        },
        downloadReportPayslipBatchExcelButtonLoading: {
            type: Boolean,
            required: true,
            default: false 
        },
        payslips: {
            type: Object,
            required: true,
            default: () => ({
                docs: [],
                totalDocs: 0
            })
        }
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            formSearch: {
                worker_name: ''
            }
        };
    },
    methods: {
        triggerUploadTemplate() {
            this.$refs.uploadTemplate.click();
        },
        uploadTemplate(event) {
            this.$emit('upload-template', event);
        },
        downloadReportPayslipBatchExcel() {
            this.$emit('download-report-payslip-batch-excel');
        },
        showLogs() {
            this.$emit('show-logs');
        },
        filterPayslipBatchDetail() {
            this.$emit('filter-payslip-batch-detail', this.formSearch.worker_name);
        },
        addWorker() {
            this.$emit('add-worker');
        },
        editWorkerPayslip({ action, workerPayslip }) {
            this.$emit('edit-worker-payslip', { action, workerPayslip });
        },
        deleteWorkerPayslip({ action, workerPayslip }) {
            this.$emit('delete-worker-payslip', { action, workerPayslip });
        },
        previewWorkerPayslip({ action, workerPayslip }) {
            this.$emit('preview-worker-payslip', { action, workerPayslip });
        },
        distributeWorkerPayslip({ action, workerPayslip }) {
            this.$emit('distribute-worker-payslip', { action, workerPayslip });
        },
        changeLimit(e) {
            this.$emit('change-limit', e);
        },
        changePage(e) {
            this.$emit('change-page', e);
        },
    }
};
</script>

<style scoped>
.myrobin-border-blue {
    border: 1px solid #0275D8;
}
.myrobin-background-yellow {
    background: #FFC737;
}

.myrobin-gap-10px {
    gap: 10px;
}

thead {
    background-color: #0ABAB5;
}
th {
    color: #ffffff;
}
</style>
